import { ApplicationConfig, InjectionToken } from '@angular/core';
import {
  RouteReuseStrategy,
  provideRouter,
  withDebugTracing,
  withInMemoryScrolling,
} from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { MAT_ICON_DEFAULT_OPTIONS } from '@angular/material/icon';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import {
  provideFirestore,
  initializeFirestore,
  connectFirestoreEmulator,
  getFirestore,
} from '@angular/fire/firestore';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { provideDatabase, getDatabase, connectDatabaseEmulator } from '@angular/fire/database';
import { provideNgxStripe } from 'ngx-stripe';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideLottieOptions } from 'ngx-lottie';
import { CacheRouteReuseStrategy } from './_shared/route-reuse/cache-route-reuse-strategy';
import { authenticationInterceptor } from './customer/interceptors/authentication-interceptor';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL', {
  providedIn: 'root',
  factory: () => environment.apiBaseUrl,
});

export const PLATFORM_INFO = new InjectionToken<{
  host: Office.HostType;
  platform: Office.PlatformType;
} | null>('PLATFORM_INFO', {
  providedIn: 'root',
  factory: () => null,
});

export function GetConfig(platformInfo: { host: Office.HostType; platform: Office.PlatformType }) {
  const appConfig: ApplicationConfig = {
    providers: [
      provideRouter(
        routes,
        withInMemoryScrolling({
          scrollPositionRestoration: 'enabled',
        }),
        withDebugTracing(),
      ),
      provideHttpClient(withInterceptors([authenticationInterceptor])),
      provideAnimations(),
      provideLottieOptions({
        player: () => import('lottie-web'),
      }),
      provideNgxStripe(environment.stripe.publishableKey),
      provideFirebaseApp(() => {
        return initializeApp(environment.firebase);
      }),
      provideAnalytics(() => {
        return getAnalytics();
      }),
      provideFirestore(() => {
        if (environment.useEmulators) {
          console.warn('Connecting to firestore emulator');
          const db = initializeFirestore(getApp(), {
            experimentalAutoDetectLongPolling: true,
          });
          const { host, port } = environment.emulatorEndpoint.firestore;
          connectFirestoreEmulator(db, host, port);
          return db;
        }
        return getFirestore();
      }),
      provideAuth(() => {
        const auth = getAuth();
        if (environment.useEmulators) {
          console.warn('Connecting to auth emulator');
          connectAuthEmulator(auth, environment.emulatorEndpoint.authentication, {
            disableWarnings: true,
          });
        }
        auth.useDeviceLanguage();
        return auth;
      }),
      provideFunctions(() => {
        const functions = getFunctions();
        if (environment.useEmulators) {
          console.warn('Connecting to functions emulator');
          const { host, port } = environment.emulatorEndpoint.functions;
          connectFunctionsEmulator(functions, host, port);
        }
        return functions;
      }),
      provideStorage(() => {
        const storage = getStorage();
        if (environment.useEmulators) {
          console.warn('Connecting to storage emulator');
          const { host, port } = environment.emulatorEndpoint.storage;
          connectStorageEmulator(storage, host, port);
        }
        return storage;
      }),
      provideDatabase(() => {
        const database = getDatabase();
        if (environment.useEmulators) {
          console.warn('Connecting to realtime database');
          const { host, port } = environment.emulatorEndpoint.realtimeDatabase;
          connectDatabaseEmulator(database, host, port);
        }
        return database;
      }),
      {
        provide: RouteReuseStrategy,
        useClass: CacheRouteReuseStrategy,
      },
      { provide: MAT_ICON_DEFAULT_OPTIONS, useValue: { fontSet: 'material-icons-round' } },
      { provide: MAT_CARD_CONFIG, useValue: { appearance: 'outlined' } },
      { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
      { provide: PLATFORM_INFO, useValue: platformInfo },
    ],
  };

  return appConfig;
}
