import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { filter, Observable, switchMap } from 'rxjs';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { GetConfig } from './app/app.config';

/**
 * Use this to check office version and display unsupported message
 */
function isSupportedOfficeVersion() {
  if (navigator.userAgent.indexOf('Trident') !== -1 || navigator.userAgent.indexOf('Edge') !== -1) {
    const unsupportedOfficeVersion = document.getElementById('unsupported-office-version');
    const angular = document.getElementById('servefast-root');
    unsupportedOfficeVersion!.style.display = 'block';
    angular!.style.display = 'none';
    return false;
  }

  return true;
}

/**
 * Use this to check if you are runing inside office enviroment
 * Office.initialize will never be called on the web
 *
 * As per documentation
 * https://learn.microsoft.com/en-us/javascript/api/office?view=common-js-preview#office-office-onready-function(1)
 */
function isRunInOfficeContext() {
  return new Observable<{
    isOfficeContext: boolean;
    platformInfo: {
      host: Office.HostType;
      platform: Office.PlatformType;
    };
  }>((observer) => {
    Office.onReady((info) => {
      if (info.host === null && info.platform === null) {
        console.log(`App is running outside of office context`);
        observer.next({ isOfficeContext: false, platformInfo: info });
        observer.complete();
      } else {
        console.log(`Office host: ${info.host}`);
        console.log(`Office Platform: ${info.platform}`);
        observer.next({ isOfficeContext: true, platformInfo: info });
        observer.complete();
      }
    });
  });
}

if (environment.production) {
  enableProdMode();
}

Office.initialize = async (reason: Office.InitializationReason) => {
  console.log(`Office initalized: ${reason.toString()}`);
};

isRunInOfficeContext()
  .pipe(
    filter(() => isSupportedOfficeVersion()),
    switchMap((ready) => {
      const appConfig = GetConfig(ready.platformInfo);
      return bootstrapApplication(AppComponent, appConfig);
    }),
  )
  .subscribe();
